<template>
  <div style="background: #F6F6F6;">
    <div class="zxyj-content-box">
			<div class="banner-content">
				<div class="banner">
					<img style="height: 400px;" src="../../assets/images/banner/products.png" alt="加载失败" />
				</div>
			</div>
		</div>
    <div class="products">
      <div class="xzyj-content">
        <div style="display: flex;align-items: center;margin-top: 30px;margin-left: 15px;">
					<el-breadcrumb separator="/">
					 <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					 <el-breadcrumb-item >{{route.meta.title}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
        <div class="gaikuang">
          <div class="prod-title">
            <div class="title">XST-20A概况</div>
            <div style="width: 75px;height: 2px; background: #112A88;margin: auto;margin-bottom: 40px;"></div>
          </div>
          <div class="gk-bcgrd">
            <div class="item">
              <div class="san"></div>
              <div class="content">江苏鑫昇腾研发的新一代升降脚手架系统产品XST-20A（智能爬架）是指搭设一定高度并附着于工程结构上，依靠自身的升降设备和装置，可随工程结构逐层爬升或下降，具有防倾覆、防坠落装置的外脚手架。全部工厂化预制、模块化生产、标准化安装。</div>
            </div>
            <div class="item">
              <div class="san"></div>
              <div class="content">随着中国高层建筑越来越多，建造的标准和要求越来越高，江苏鑫昇腾研发的新一代升降脚手架系统产品XST-20A（智能爬架）脱颖而出，被广泛应用于各大建筑，成为智能建造的重要推动力一，助力建筑业更好地发展。</div>
            </div>
          </div>
        </div>
        <div class="gouzao">
          <div class="prod-title">
            <div class="title">XST-20A构造</div>
            <div style="width: 75px;height: 2px; background: #112A88;margin: auto;margin-bottom: 40px;"></div>
          </div>
          <div style="font-size: 14px;font-family: MicrosoftYaHei;color: #333333;">XST-20A主要由架体结构、附着支座、防倾装置、防坠落装置、升降机构及控制装置等构成。</div>
          <div style="width: 1200px; height: 557px;margin-top: 34px;"><img src="../../assets/images/products/Product-Intro-structure-img.png" alt=""></div>
          <div style="font-size: 16px;font-family: MicrosoftYaHei;color: #333333;line-height: 21px;padding-top: 24px;background: #FFF;">物联系统<div style="width: 132px;height: 2px;background: #112A88;margin: auto;margin-top: 8px;"></div></div>
          <div class="wlxt">
            <div class="wlxt-item">
              <div class="wlxt-titel">远程操控系统</div>
              <div class="wlxt-content">采集电箱当前工作状态，展示当前位置是否正常工作，远程操控架体运行</div>
            </div>

            <div class="wlxt-item">
              <div class="wlxt-titel">安全监管系统</div>
              <div class="wlxt-content">通过采集架体承重和架体平整度数据，超过设定值，该点位报警。平台同步展示警戒值</div>
            </div>

            <div class="wlxt-item">
              <div class="wlxt-titel">激光雷达系统</div>
              <div class="wlxt-content">采集架体当前海拔高度， 自动展示架体当前工作 楼层</div>
            </div>

            <div class="wlxt-item">
              <div class="wlxt-titel">视频监控系统</div>
              <div class="wlxt-content">通过摄像头现场监控，预 防危险操作和确定设备安 全状况</div>
            </div>

            <div class="wlxt-item">
              <div class="wlxt-titel">NB-IOT系统</div>
              <div class="wlxt-content">自主研发的物联网芯片， 自动采集数据，上传云 端，不可篡改</div>
            </div>
          </div>
        </div>
        <div class="youshi">
          <div class="prod-title">
            <div class="title">XST-20A优势</div>
            <div style="width: 75px;height: 2px; background: #112A88;margin: auto;margin-bottom: 40px;"></div>
          </div>
          <div style="font-size: 14px;font-family: MicrosoftYaHei;color: #333333;">XST-20A本身省钢、省电、省工，施工过程中具有明显的降噪防尘特点，生产加工过程中使用喷塑/喷漆工艺、外观靓丽，无火灾隐患、全封闭施工，安全高效。</div>
          <div style="margin-top: 40px;  display: flex; justify-content: space-evenly;">
            <div class="ys-item">
              <div class="ys-item-title"><img style="width: 76px; height: 76px;" src="../../assets/images/products/ProductIntro-ico-safe.png"/>更安全</div>
              <div class="ys-item-content">架体同步提升控制系统，可以让架体在高空中保持水平偏差不超过1CM，相较于绝大部分全钢型附着式升降脚手架有着绝对的优势，大大减少人员伤亡事件的比例。</div>
            </div>

            <div class="ys-item">
              <div class="ys-item-title"><img style="width: 76px; height: 76px;" src="../../assets/images/products/ProductIntro-ico-更迅捷备份.png"/>更迅捷</div>
              <div class="ys-item-content">XST-20A（智能爬架）提升时间为45分钟提升一层，在施工进度紧张的工地，我们可以完全保证施工进度跟得上主体施工，做到比施工层高1.5-2M，不拖延工期不冒顶。</div>
            </div>

            <div class="ys-item">
              <div class="ys-item-title"><img style="width: 76px; height: 76px;" src="../../assets/images/products/ProductIntro-ico-更省力备份.png"/>更省力</div>
              <div class="ys-item-content">上架体只需一次拼装，拼装过程可在一月以内完成，随后可 以多次提升，搭设架体需5至8 人，提升只需3人，大大减少劳务成本。</div>
            </div>

            <div class="ys-item">
              <div class="ys-item-title"><img style="width: 76px; height: 76px;" src="../../assets/images/products/ProductIntro-ico-更实用备份.png"/>更实用</div>
              <div class="ys-item-content">XST-20A架体高度可随楼层高度调整，适应现今所有高度的不同楼栋，又专门针对变化较大楼层设计特殊非标材料10余种，对现有建筑的适用率高达90%以上。</div>
            </div>

            <div class="ys-item">
              <div class="ys-item-title"><img style="width: 76px; height: 76px;" src="../../assets/images/products/ProductIntro-ico-更先进备份.png"/>更先进</div>
              <div class="ys-item-content">结合工业物联技术，利用远程控制芯片和控制系统，可支持远程监控控制，让项目管理人员对项目进度实施监控，做到放心安心。</div>
            </div>
          </div>
        </div>
        <div class="pinzhi">
          <div class="prod-title">
            <div class="title">品质保障</div>
            <div style="width: 75px;height: 2px; background: #112A88;margin: auto;margin-bottom: 40px;"></div>
          </div>
          <div style="display: flex;flex-direction: row;flex-wrap:wrap;justify-content: space-between;">
            <div class="itemClzBox">
              <div class="itemClz" style="display: flex;flex-direction: column;">
                <div class="itemTitle" style="display: flex;">原材料</div>
                <div class="itemSub" style="display: flex;">严选优质原材料及各类型配件，从源头控制产品质量</div>
              </div>
              <div style="display: flex;">
                <img src="../../assets/images/products/productIntro-material-img备份 2.png" />
              </div>
            </div>
            <div class="itemClzBox">
              <div class="itemClz" style="display: flex;flex-direction: column;">
                <div class="itemTitle" style="display: flex;">设计</div>
                <div class="itemSub" style="display: flex;">设计部件标准化程度高</div>
              </div>
              <div style="display: flex;">
                <img src="../../assets/images/products/productIntro-design-img备份 2.png" />
              </div>
            </div>
            <div class="itemClzBox">
              <div class="itemClz" style="display: flex;flex-direction: column;">
                <div class="itemTitle" style="display: flex;">物流管控</div>
                <div class="itemSub" style="display: flex;">利用先进的物流管控技术和手段，严格控制物流损失</div>
              </div>
              <div style="display: flex;">
                <img src="../../assets/images/products/productIntro-transation-img备份 2.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
	export default {
		metaInfo: {
      title: "智能爬架产品展示-鑫昇腾",
      meta:[
				{name:"keywords", content: "爬架,建筑爬架,智能爬架,全钢爬架,鑫昇腾"},
				{name:"description", content: "江苏鑫昇腾科技发展股份有限公司专注于建筑爬架的设计、制造和专业承包，提供爬架租赁、销售、运营、安全监管、技术服务等一站式解决方案。"}
			]
		},
		data() {
			return {
				route:this.$route,
			}
		},
		created() {
		},
		methods: {// 提交留言
		
		}
	};
</script>

<style lang="scss" scoped>
  .products{display: flex;justify-content: center;}
  .banner-content {margin-top: 68px; position: relative; height: 400px; width: 100%;}
	.banner {height: 100%; width: 100% !important;overflow: hidden;	text-align: center;	margin: 0 auto;	padding: 0;	z-index: 1;	}
	.banner img {width: auto;	margin: 0 -100%;max-width: none;}
  .gaikuang{margin-top: 40px;}
  .prod-title{
    text-align: center;
    .title{font-size: 36px;font-family: MicrosoftYaHeiSemibold;color: #333333;line-height: 48px;}
  }
  .gk-bcgrd{
    background-image: url(../../assets/images/products/ProductIntro-bg-img.png);width: 1200px; height: 200px; display: flex; justify-content: space-evenly; flex-direction: column; padding: 0 24px 0 24px;
    .item {display: flex; flex-direction: row;}
    .san{width: 0;height: 0; border-left: 15px solid white; border-bottom:8px solid transparent; border-top:8px solid transparent;}
    .content{width: 1122px;font-size: 14px; font-family: MicrosoftYaHei; line-height: 22px; color: #FFF;margin-left: 20px;}
  }
  .gouzao {
    margin-top: 100px;text-align: center;font-size: 14px;font-family: MicrosoftYaHei;color: #333333;line-height: 19px;
  }
  .wlxt{
    display: flex; justify-content: space-evenly;padding-top: 22px;height: 150px;background: #FFF;
    .wlxt-item {text-align: left;width: 195px;}
    .wlxt-title {font-size: 16px;color: #333333;line-height: 21px;letter-spacing: 1px;font-family: MicrosoftYaHei;}
    .wlxt-content {font-size: 14px; color: #666666;line-height: 24px;letter-spacing: 1px;font-family: MicrosoftYaHei;margin-top: 10px;}
  }
  .youshi{margin-top: 100px;text-align: center;}
  .ys-item{width: 220px;height: 303px;background: #FFFFFF;display: flex;flex-direction: column; align-items: center;padding: 32px 28px;}
  .ys-item-title{font-size: 14px; font-family: MicrosoftYaHei; color: rgb(51, 51, 51); line-height: 30px; display: flex; flex-direction: column;}
  .ys-item-content{font-size: 12px;font-family: MicrosoftYaHei;color: #666666;line-height: 20px;margin-top: 10px;}
  .itemTitle{	width: 80px;	height: 27px;	font-size: 20px;font-family: MicrosoftYaHei;color: #112A88;line-height: 27px;}
	.itemClzBox{
		width: 368px;	height: 363px;	display: flex;	flex-direction: column;	justify-content: space-between;
		background: #FFF;		// border:1px dashed #000000;		margin-top: 24px;
	}
	.itemClzBox img{height: 270px;}
	.itemClz{	justify-content: center;width: 340px;	height: 92px;background: #FFFFFF;	border-radius: 2px 2px 0px 0px;	margin-left: 20px;
	}
  .pinzhi {margin-top: 100px;margin-bottom: 100px;}
</style>